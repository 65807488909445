import { Component, OnInit, Inject } from '@angular/core';
import { Validators, FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Router, ActivatedRoute } from '@angular/router';
import { SubscriptionService } from '../../../services/subscription/subscription.service';
import { CommonService } from '../../../services/common/common.service';

@Component({
  selector: 'app-add-edit-point',
  templateUrl: './add-edit-point.component.html',
  styleUrls: ['./add-edit-point.component.css']
})
export class AddEditPointComponent implements OnInit {
  addPointsSetup: FormGroup;
  subscriptionPoint = new FormControl('', [Validators.required]);
  subscription_id: number = 0;
  subscription_user_id: number = 0;

  constructor(
    _formbuilder: FormBuilder,
    public dialogRef: MatDialogRef<AddEditPointComponent>, @Inject(MAT_DIALOG_DATA) 
    public subscriptionData:any,
		private dialog: MatDialog,
		private ar: ActivatedRoute, 
    private router: Router,
    private common: CommonService,
    private Subscription: SubscriptionService
  ) {
    this.subscription_id = + this.subscriptionData.id;
    this.subscription_user_id = + this.subscriptionData.user_id;
    this.addPointsSetup = _formbuilder.group({
      subscriptionPoint: this.subscriptionPoint
    });
   }

  ngOnInit() {
    console.log('this.subscription_id', this.subscription_id);
    console.log('this.subscription_user_id', this.subscription_user_id);
  }

  addPoint(){
    console.log(this.addPointsSetup.valid);
    console.log(this.addPointsSetup);
    if(this.addPointsSetup.valid){
      const formData = {
        user_id : this.subscription_user_id,
        subscription_id : this.subscription_id,
        points : this.addPointsSetup.value.subscriptionPoint
      }
      this.Subscription.addPoint(formData).subscribe(
        res => {
          if(res['status'] == 1){
            this.closePopup();
          }
          else{
            this.common.openSnackBar('Point is not save please try later', '');
          }
        },
        err => {
          console.log("ERROR");
          console.log(err);
          this.common.openSnackBar('please try later', '');
        }
      );
    }
  }

  closePopup() {
    this.dialogRef.close();
  }

}
